.container-main-validacao {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.header-validacao {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 10px;
}

.header-validacao > img {
  width: 90px;
}

.body-validacao {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.body-validacao > h5 {
  font-size: 18px;
  color: #00955f;
  max-width: 235px;
  text-align: center;
}

.checkmarkLogo {
  margin-bottom: 25px;
  border: 4px solid #00955f;
  border-radius: 50%;
  padding: 30px;
}

.checkmarkLogo > img {
  width: 150px;
}
