.bodyValidRemota {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background-color: #006842ad;
  color: white;
  text-align: center;
  padding: 20px;
}

.header-validacao {
  margin-bottom: 10px;
}

.logo {
  width: 80px;
}

.status h3 {
  margin-top: 10px;
  color: white;
  font-size: 18px;
}

.video-container {
  width: 80%; /* O vídeo ocupa 80% da largura */
  height: auto;
  border: 5px solid rgba(255, 255, 255, 0.8);
  border-radius: 50% / 30%; /* Define o formato oval */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h4 {
  margin-top: 10px;
  font-size: 16px;
  color: white;
}
