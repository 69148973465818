.container-login > .row,
.container-login > .col-md-12 {
  height: 100%;
}

.container-login .col-md-12 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.container-login a {
  text-decoration: underline !important;
}

.card-login {
  width: 500px !important;
  padding: 3rem;
}

.card-login .container-logo {
  text-align: center;
}

.card-login .logo {
  width: 15rem;
}

form h2 {
  font-weight: lighter !important;
}

.container-termos {
  font-size: 14px;
  margin-top: 1rem;
  font-weight: 300;
  color: #717171;
}

.container-termos p {
  margin: 0;
}

.container-termos a {
  color: #00a7dc !important;
}

.logo-mini {
  width: 2.5rem;
}

.card-login .invalid-feedback ul {
  margin-bottom: 0px !important;
}

footer {
  margin-top: 15px;
  display: flex !important;
  flex-direction: row;
  font-size: 15px;
  color: gray;
}

footer small {
  margin-left: 15px;
  font-weight: 300;
}

@media only screen and (max-width: 700px) {
  .card-login {
    width: 95% !important;
    padding: 3rem;
  }
}

.btnLogin {
  background-color: #ff8134 !important;
  border-color: #ff8134 !important;
}

.btnLogin:hover {
  color: #fff;
  background-color: #ff8134 !important;
  border-color: #ff8134 !important;
  box-shadow: 0 0 0 0.2rem #f56b15 !important;
}

.btnLogin.focus,
.btnLogin:focus {
  box-shadow: 0 0 0 0.2rem #f56b15 !important;
  background-color: #ff8134 !important;
  border-color: #ff8134 !important;
}
.btnLogin:disabled {
  background-color: #ff8134 !important;
  border-color: #ff8134 !important;
}

.btnLogin:active {
  color: #fff;
  background-color: #ff8134 !important;
  border-color: #ff8134 !important;
  box-shadow: 0 0 0 0.2rem #f56b15 !important;
}

.liveness-detector {
  width: 35%;
  min-width: 300px;
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 720px) {
  .liveness-detector {
    width: 35%;
    min-width: 400px;
    margin-top: auto;
    margin-bottom: auto;
  }

  #root > div[data-amplify-theme] {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
}
