.validationTerms-page.container > .container > .row {
  background-color: #ffffff;
  color: #707070;
}

@media (max-width: 719px) {
  .blocoImageEmpresaTermo {
    background-color: red;
    /* margin-left: auto !important;
    margin-right: auto !important; */
    width: 100%;
  }
  .empresaTermoText {
    font-size: 14px;
  }
  .termoText {
    height: 25vh;
  }
  .title {
    font-size: 14px;
  }
  .titleTermoAll {
    font-size: 14px;
  }
}

@media (max-height: 720px) and (min-width: 720px) {
  .termoText {
    height: 25vh;
  }
}

@media (min-height: 721px) and (max-height: 768px) and (min-width: 720px) {
  .termoText {
    height: 25vh;
  }
}

@media (min-height: 769px) and (max-height: 899px) and (min-width: 720px) {
  .termoText {
    height: 25vh;
  }
}

@media (min-height: 900px) and (max-height: 1079px) and (min-width: 720px) {
  .termoText {
    height: 25vh;
  }
}

@media (min-height: 1080px) and (max-height: 2160px) and (min-width: 720px) {
  .termoText {
    height: 25vh;
  }
}

@media (min-height: 2161) and (max-height: 4320) and (min-width: 720px) {
  .termoText {
    height: 25vh;
  }
}

@media (min-height: 4230) and (min-width: 720px) {
  .termoText {
    height: 25vh;
  }
}

.inputTermoG > .form-check-input {
  transform: scale(1.4);
  margin-right: 10px;
}

.inputTermoG > label {
  font-size: 14px;
  margin-left: 10px;
}

.arcodeonTermo {
  border: none !important;
  border-radius: 0 !important;
  height: 50px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.accordion__button {
  padding-top: 10px !important;
  padding-bottom: 15px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: #ffffff !important;
}

.accordion__button:hover {
  background-color: transparent !important;
}

.accordion__panel {
  padding: 0 !important;
}
