@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body,
.container-fluid,
#root {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  font-size: 13px !important;
  touch-action: pan-x pan-y;
}

tbody:nth-child(2n) {
  background-color: unset !important;
}

.container-fluid > .row {
  margin: 0 !important;
  padding: 0 !important;
}

a {
  color: inherit !important;
}

label {
  margin-bottom: 0px !important;
  color: #717171;
}

.btn.btn-primary,
.btn.btn-secondary {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  padding-block: 10px;
}

.btn-primary {
  background-color: #00955f;
  border-color: #00955f;
}

.btn-primary:hover {
  color: #fff;
  background-color: #006842;
  border-color: #006842;
  box-shadow: 0 0 0 0.2rem #006842;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #00955f;
  background-color: #00955f;
  border-color: #00955f;
}

.btn-primary:disabled {
  background-color: #00955f;
  border-color: #00955f;
}

.btn-primary:active {
  color: #fff;
  background-color: #00955f;
  border-color: #00955f;
  box-shadow: 0 0 0 0.2rem #00955f;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  box-shadow: 0 0 0 0.2rem #00955f;
}

.btn.btn-secondary {
  background-color: #ffffff;
  border-color: #00955f;
  color: #00955f;
}

.terms-buttons .btn.btn-secondary:hover {
  box-shadow: 0 0 0 0.2rem #006842 !important;
  border-color: #006842 !important;
  color: #006842 !important;
}

.btn.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem #00955f;
}

.form-control.is-invalid,
.invalid-feedback {
  border-color: #f8bdc2 !important;
  color: #fd818b !important;
  font-weight: 300;
}

td,
th {
  vertical-align: middle !important;
}

.ellipsis {
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.ellipsis-card-menu-flex {
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.react-datepicker-wrapper input[type="text"] {
  width: 25rem;
}

@media (max-width: 991px) {
  .react-datepicker-wrapper input[type="text"] {
    width: 14rem;
  }
}

@media (max-width: 486px) {
  .react-datepicker-wrapper input[type="text"] {
    width: 24rem;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00955f;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.scroller {
  overflow-y: scroll;
  scrollbar-color: #707070 transparent;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.scroller::-webkit-scrollbar {
  width: 8px;
  background-color: #707070;
}

.scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.input-filter-table {
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  background: white;
  width: 150px;
  padding: 2px;
}

.label-filter-table {
  display: block;
}

.button-filter-table {
  border: none;
  background: #0288b9;
  color: white;
  width: 150px;
  margin-top: 18px;
  padding: 2px;
  border-radius: 4px;
}

video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

#multiselectContainerReact {
  background-color: white;
}

.page-item.active .page-link {
  background-color: var(--theme-color-pagination);
  border-color: var(--theme-color);
}

.Col-Menu {
  background-color: var(--theme-color) !important;
}

.buttonTheme {
  color: var(--theme-color);
}

.buttonTheme:active {
  color: var(--theme-color);
}

.buttonTheme:not(:disabled):not(.disabled).active,
.buttonTheme:not(:disabled):not(.disabled):active,
.show > .buttonTheme.dropdown-toggle {
  color: var(--theme-color);
}

.buttonTheme:hover {
  color: var(--theme-color);
}

.buttonTheme:focus {
  color: var(--theme-color);
}

.buttonOutLine {
  color: var(--theme-color) !important;
  background-color: transparent !important;
}

.buttonOutLineCancel {
  color: #00955f !important;
  background-color: transparent !important;
}
:root {
  /*--theme-color: #FF8134;
  --theme-color-active: #f56b15;
  --theme-color-pagination : #FF8134;
  --theme-color-border :#f56b15/*/
}

.tableBoots > .react-bootstrap-table {
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 5px;
}

.marcaDagua {
  transform: rotate(-15deg);
}

.marcaDaguaCnh {
  transform: rotate(-45deg);
}
.form-group.form-input-group .form-control,
.form-label {
  font-size: 16px;
}
