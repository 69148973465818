div.stepsPage {
  background-color: #ffff;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

div.stepsPage > header {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
}



div.stepsContent {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

  div.stepsContent > img {
    margin-bottom: 1rem;
    width: 147px;
  }
  div.stepsPage > header > img {
    width: 36px;
  }


@media (max-width: 400px) and (max-height:700px) {
  div.stepsContent > img {
    margin-bottom: 1rem;
    width: 84px;
  }
  div.stepsPage > header > img {
    width: 27px;
  }
}

@media (max-width: 400px) and  (min-height:701px) and (max-height:900px) {
  div.stepsContent > img {
    margin-bottom: 1rem;
    width: 175px;
  }
  div.stepsPage > header > img {
    width: 58px;
  }
}

@media  (min-width:401px) and (max-width: 790px) and (min-height:896px) and (max-height:1100px) {
  div.stepsContent > img {
    margin-bottom: 1rem;
    width: 250px;
  }
  div.stepsPage > header > img {
    width: 65px;
  }
}

@media  (min-width:791px) and (max-width: 890px) and (min-height:1101px) and (max-height:1200px) {
  div.stepsContent > img {
    margin-bottom: 1rem;
    width: 298px;
  }
  div.stepsPage > header > img {
    width: 65px;
  }
}


@media (min-width:891px) and (max-width: 1100px) and (min-height:1201px) and (max-height:1400) {
  div.stepsContent > img {
    margin-bottom: 1rem;
    width: 298px;
  }
  div.stepsPage > header > img {
    width: 65px;
  }
}

@media (min-width: 400px) and (max-width:600px) and (max-height:720px) {
  div.stepsContent > img {
    margin-bottom: 1rem;
    width: 155px;
  }
  div.stepsPage > header > img {
    width: 40px;
  }
}




div.stepsContent > h2.stepsImgTitle {
  color: #000;
  font-size: 15px;
  font-weight: bold;
  max-width: 230px;
  text-align: center;
}

div.stepsContent > div.stepsToFollow {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 25px;
  gap: 20px;
  max-width: 350px;
  margin-bottom: 80px;
}

div.stepsContent > div.stepsToFollow > div.step {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #707070;
}

div.stepsContent > div.stepsToFollow > div.step > p {
  font-size: 15px;
  text-align: left;
  margin: 0px;
}

div.stepsContent > .stepsConfirmBtn.btn.btn-light {
  height: 56px;
  width: 200px;
  font-size: 18px;
  margin-top: 1rem;
  color: #333;
}
