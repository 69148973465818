.actionsButtons{
    display: flex;
    flex-direction: column;
    width:80%;
    gap:20px;
}

.actionsButtonsConfirm{
    width:100%;
    height:56px;
    font-size:18px !important;
}

.actionsButtonsCancel{
    width:100%;
    height:56px;
    font-size:18px !important;
}


.swal2-html-container{
    font-size:19px !important;
}